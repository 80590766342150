import React from "react";
import { graphql } from "gatsby";
import { DataConnection, PageQueryProps } from "~/model";
import Seo from "~/components/Seo";
import ResearchList, {
  ResearchListArticleFileData,
} from "~/features/marketing/ResearchList";

type ResearchPageProps = PageQueryProps<{
  readonly allFile: DataConnection<{
    readonly childMarkdownRemark: ResearchListArticleFileData;
  }>;
}>;

function ResearchPage({ data }: ResearchPageProps) {
  return (
    <ResearchList
      articles={data.allFile.edges.map(({ node }) => node.childMarkdownRemark)}
    />
  );
}

export const pageQuery = graphql`
  query ResearchPageQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "research" } }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            ...ResearchList_article
          }
        }
      }
    }
  }
`;

export function Head() {
  return <Seo title="Research" />;
}

export default ResearchPage;
